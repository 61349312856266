@font-face {
  font-family: "hbicons";
  src: url("/etc.clientlibs/bnpparibas-neo-hb-acquisition-site-front/clientlibs/clientlib-base/resources/fonts/hbicons.woff2") format("woff2"), url("/etc.clientlibs/bnpparibas-neo-hb-acquisition-site-front/clientlibs/clientlib-base/resources/fonts/hbicons.woff") format("woff");
  font-display: swap;
  font-weight: normal;
  font-style: normal;
}
@font-face {
  src: url("/etc.clientlibs/bnpparibas-neo-hb-acquisition-site-front/clientlibs/clientlib-base/resources/fonts/.woff2") format("woff2"), url("/etc.clientlibs/bnpparibas-neo-hb-acquisition-site-front/clientlibs/clientlib-base/resources/fonts/.woff") format("woff");
  font-display: swap;
  font-weight: normal;
  font-style: normal;
}
[class^=hbi-],
[class*=" hbi-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "hbicons" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.hbi-burger-menu:before {
  content: "\e900";
}

.hbi-search:before {
  content: "\e90e";
}

.hbi-enter:before {
  content: "\e91b";
}

.hbi-cross:before {
  content: "\e901";
}

.hbi-chevron-down:before {
  content: "\e904";
}

.hbi-chevron-left:before {
  content: "\e905";
}

.hbi-chevron-right:before {
  content: "\e906";
}

.hbi-phone:before {
  content: "\e90d";
}

.hbi-user:before {
  content: "\e903";
}

.hbi-info:before {
  content: "\e91d";
}

.hbi-facebook:before {
  content: "\e909";
}

.hbi-twitter:before {
  content: "\e90f";
}

.hbi-instagram:before {
  content: "\e966";
}

.hbi-youtube:before {
  content: "\e910";
}

.hbi-download:before {
  content: "\e91c";
}

.hbi-envelope:before {
  content: "\e921";
}

.hbi-spark:before {
  content: "\e911";
}

.hbi-zero-alt:before {
  content: "\e941";
}

.hbi-avenir:before {
  content: "\e9db";
}

.hbi-locker:before {
  content: "\e916";
}

.hbi-faq {
  position: relative;
}
.hbi-faq:before {
  position: relative;
  left: 0;
  content: "\e90a";
}
.hbi-faq:after {
  position: absolute;
  content: "\e90b";
  opacity: 0.5;
  left: 0;
}

.hbi-chat-conseiller {
  position: relative;
}
.hbi-chat-conseiller:before {
  content: "\e903";
  position: relative;
}
.hbi-chat-conseiller:after {
  content: "\e908";
  position: absolute;
  right: -5px;
  top: -6px;
}

/* ------------------------------------*\
  #NAVIGATION PRINCIPALE ACQUISITION
\*------------------------------------ */
._comp_aem_layout_header {
  --mobile-fs: 12px;
  --tablet-fs: 14px;
  --desktop-fs: 16px;
  --pale-grey: #dde2e8;
  --dark-grey: #4e5152;
  --pale-blue: #ebf6f8;
  --z-index-nav: 100;
  --root-fs: var(--mobile-fs);
  background-color: #fff;
  /* ------------------------------------*\
    #PRE MAIN NAV
  \*------------------------------------ */
  /* stylelint-disable-next-line no-duplicate-selectors */
  /* ------------------------------------*\
    #MAIN NAVIGATION
  \*------------------------------------ */
  /* ------------------------------------*\
    #SUBLINKS IN POPUP
  \*------------------------------------ */
  /* stylelint-disable selector-id-pattern */
  /* ------------------------------------*\
    #NAVIGATION POPUP
  \*------------------------------------ */
  /* ------------------------------------*\
    #OFFER
  \*------------------------------------ */
  /* ------------------------------------*\
    #NAVIGATION FOR LOGIN & REGISTER
  \*------------------------------------ */
  /* ------------------------------------*\
    #MOBILE SPECIFIC
  \*------------------------------------ */
  /* stylelint-disable-next-line no-duplicate-selectors */
  /* classe pour cacher/afficher une div qui a une transition */
}
@media (min-width: 768px) {
  ._comp_aem_layout_header {
    --root-fs: var(--tablet-fs);
  }
}
@media (min-width: 1024px) {
  ._comp_aem_layout_header {
    --root-fs: var(--desktop-fs);
  }
}
@media (min-width: 768px) {
  ._comp_aem_layout_header .visible-xs {
    display: none !important;
  }
}
@media (max-width: 767px) {
  ._comp_aem_layout_header .hidden-xs {
    display: none !important;
  }
  ._comp_aem_layout_header .visible-xs {
    display: block !important;
  }
}
._comp_aem_layout_header .btn {
  font-size: var(--mobile-fs);
}
@media (min-width: 1024px) {
  ._comp_aem_layout_header .btn {
    font-size: 0.8rem;
  }
}
._comp_aem_layout_header .container.container--header {
  padding-block-start: revert;
  padding-inline: 0;
}
@media (min-width: 1024px) {
  ._comp_aem_layout_header .container.container--header {
    padding-inline: 2em;
  }
}
._comp_aem_layout_header .global_header_not_connected .pre-nav-container {
  transition: height 150ms;
  display: flex;
  align-items: center;
  padding-inline: var(--header-py);
}
@media (max-width: 1023px) {
  ._comp_aem_layout_header .global_header_not_connected .pre-nav-container {
    background-color: var(--color-brand-900);
    height: 40px;
  }
}
._comp_aem_layout_header .global_header_not_connected .pre-nav-container .logo-nav {
  height: 64px;
  width: 58px;
  margin-right: 1.5rem;
}
@media (max-width: 1023px) {
  ._comp_aem_layout_header .global_header_not_connected .pre-nav-container .logo-nav {
    display: none;
  }
}
._comp_aem_layout_header .global_header_not_connected .pre-nav-container__links-list {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
._comp_aem_layout_header .global_header_not_connected .pre-nav-container__links-list__item {
  padding: 0.625rem 1.5rem;
}
@media (max-width: 1023px) {
  ._comp_aem_layout_header .global_header_not_connected .pre-nav-container__links-list__item {
    padding: 0.375rem 1rem;
  }
}
._comp_aem_layout_header .global_header_not_connected .pre-nav-container__links-list__item a,
._comp_aem_layout_header .global_header_not_connected .pre-nav-container__links-list__item span {
  display: block;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 500;
  color: var(--dark-grey);
  font-size: 0.75rem;
}
@media (max-width: 1023px) {
  ._comp_aem_layout_header .global_header_not_connected .pre-nav-container__links-list__item a,
  ._comp_aem_layout_header .global_header_not_connected .pre-nav-container__links-list__item span {
    color: #fff;
    font-size: 0.8rem;
  }
}
@media (min-width: 1024px) {
  ._comp_aem_layout_header .global_header_not_connected .pre-nav-container__links-list__item a:hover {
    color: #007fb2;
  }
}
._comp_aem_layout_header .global_header_not_connected .pre-nav-container__links-list__item--part {
  border: 1px solid var(--dark-grey);
  border-radius: 30px;
}
@media (max-width: 1023px) {
  ._comp_aem_layout_header .global_header_not_connected .pre-nav-container__links-list__item--part {
    border-color: #fff;
  }
}
._comp_aem_layout_header {
  --header-h: 64px;
}
@media (min-width: 1024px) {
  ._comp_aem_layout_header:has(.main-header-nav--over) {
    --header-h: 80px;
  }
}
._comp_aem_layout_header .logo-nav {
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(/content/dam/hellobank-neo-aquisition/components/header/legacy/logoHB_baseline_white.webp);
  width: 58px;
  height: 64px;
}
._comp_aem_layout_header .global_header_not_connected .main-header-nav {
  position: relative;
  width: 100%;
  padding-inline: var(--header-py);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--header-h);
}
@media (max-width: 1023px) {
  ._comp_aem_layout_header .global_header_not_connected .main-header-nav {
    box-shadow: 0 0 28px 0 rgba(120, 128, 138, 0.05);
  }
}
._comp_aem_layout_header .global_header_not_connected .main-header-nav ._componentContainer._nextoutil {
  display: none;
}
._comp_aem_layout_header .global_header_not_connected .main-header-nav .burger-menu-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
@media (min-width: 1024px) {
  ._comp_aem_layout_header .global_header_not_connected .main-header-nav .burger-menu-wrapper {
    display: none;
    width: initial;
  }
}
._comp_aem_layout_header .global_header_not_connected .main-header-nav .logo-nav {
  width: 45px;
  height: 50px;
  margin-right: 1.5rem;
}
@media (max-width: 1023px) {
  ._comp_aem_layout_header .global_header_not_connected .main-header-nav .logo-nav {
    width: 44px;
    height: 48px;
  }
}
._comp_aem_layout_header .global_header_not_connected .main-header-nav .main-nav {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
._comp_aem_layout_header .global_header_not_connected .main-header-nav .main-nav__main-links {
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: flex;
  justify-content: start;
  align-items: center;
}
._comp_aem_layout_header .global_header_not_connected .main-header-nav .main-nav__main-links__link .btn-nav {
  border: none;
  background: none;
  font-weight: 500;
  text-transform: uppercase;
  text-decoration: none;
  color: var(--color-blue-1100);
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
  font-size: 14px;
  padding: 1rem 0.65rem;
}
._comp_aem_layout_header .global_header_not_connected .main-header-nav .main-nav__main-links__link .btn-nav:hover, ._comp_aem_layout_header .global_header_not_connected .main-header-nav .main-nav__main-links__link .btn-nav[aria-expanded=true] {
  color: var(--color-brand-900);
}
@media (width >= 1024px) and (width <= 1135px) {
  ._comp_aem_layout_header .global_header_not_connected .main-header-nav .main-nav__main-links__link .btn-nav {
    font-size: 0.8rem;
    padding-inline: 0.45rem;
  }
}
._comp_aem_layout_header .global_header_not_connected .main-header-nav .main-nav__main-links__link:first-child .btn-nav {
  padding-left: 0;
}
._comp_aem_layout_header .global_header_not_connected .main-header-nav .main-nav__main-links__link a.btn-nav {
  display: block;
}
._comp_aem_layout_header .global_header_not_connected .main-header-nav .main-nav__main-links__link .btn-nav-search {
  cursor: pointer;
  background-color: transparent;
  color: #78808a;
  font-size: 1em;
  border-radius: 50px;
  border: solid 1px var(--pale-grey);
  padding: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  touch-action: manipulation;
  white-space: nowrap;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0.3px;
  line-height: 1;
}
._comp_aem_layout_header .global_header_not_connected .main-header-nav .main-nav__main-links__link .btn-nav-search .hbi-search {
  display: inline-block;
  font-size: 1.6rem;
  color: var(--color-brand-900);
  aspect-ratio: 1/1;
}
._comp_aem_layout_header .global_header_not_connected .main-header-nav .main-nav__main-links__link .btn-nav-search__text {
  display: none;
}
._comp_aem_layout_header .global_header_not_connected .main-header-nav--over {
  background-color: #fff;
}
@media (min-width: 1024px) {
  ._comp_aem_layout_header .global_header_not_connected .main-header-nav--over {
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
    transition: all 300ms, left 0s, width 0s, padding 0s;
    left: calc(50% - 50vw + var(--scrollbar-width) / 2);
    width: calc(100vw - var(--scrollbar-width));
  }
}
@media (min-width: 90rem) {
  ._comp_aem_layout_header .global_header_not_connected .main-header-nav--over {
    padding-inline: calc(50vw - 50% - var(--scrollbar-width) / 2);
  }
}
._comp_aem_layout_header .global_header_not_connected .main-header-nav--over .burger-menu-wrapper {
  display: flex;
}
._comp_aem_layout_header .header-sublink {
  color: var(--dark-grey);
  text-decoration: none;
  font-family: var(--font-base);
  font-weight: 500;
  width: 100%;
  padding: 0.5rem 1rem 0.5rem 0.5rem;
  border-radius: 0.75em;
  border: solid 1px var(--pale-grey);
  display: flex;
  align-items: center;
}
@media (max-width: 1023px) {
  ._comp_aem_layout_header .header-sublink {
    padding: 0.85em 1em 0.85em 0.5em;
  }
}
._comp_aem_layout_header .header-sublink:hover {
  background-color: var(--pale-blue);
  color: var(--color-brand-900);
}
._comp_aem_layout_header .header-sublink:has(img[src*=".svg"]) {
  padding: 0.25rem 1rem 0.25rem 0.25rem;
}
._comp_aem_layout_header .header-sublink--populaire {
  border-color: var(--color-brand-900);
}
._comp_aem_layout_header .header-sublink--populaire:hover {
  border-color: var(--color-brand-900);
}
._comp_aem_layout_header .header-sublink__img-wrapper:has(img[src*=".svg"]) {
  background-color: var(--pale-blue);
  padding: 0.5rem;
  border-radius: 0.5rem;
  flex-shrink: 0;
}
@media (max-width: 1023px) {
  ._comp_aem_layout_header .header-sublink__img-wrapper:has(img[src*=".svg"]) {
    padding: 0.75em;
  }
}
._comp_aem_layout_header .header-sublink__img-wrapper img {
  width: auto;
  height: 2rem;
}
@media (max-width: 1023px) {
  ._comp_aem_layout_header .header-sublink__img-wrapper img {
    height: 2.5em;
  }
}
._comp_aem_layout_header .header-sublink__img-wrapper img[src*=".svg"] {
  height: 1.5rem;
}
@media (max-width: 1023px) {
  ._comp_aem_layout_header .header-sublink__img-wrapper img[src*=".svg"] {
    height: 2em;
  }
}
._comp_aem_layout_header .header-sublink__text-wrapper {
  margin-left: 1em;
  margin-right: 0.5rem;
}
@media (max-width: 1023px) {
  ._comp_aem_layout_header .header-sublink__text-wrapper {
    margin-left: 1.25em;
  }
}
._comp_aem_layout_header .header-sublink__pill {
  background-color: var(--color-brand-900);
  color: #fff;
  text-transform: uppercase;
  font-size: 0.5625rem;
  font-weight: 500;
  margin-left: auto;
  padding: 0.25em 0.625em;
  border-radius: 1em;
}
@media (max-width: 1023px) {
  ._comp_aem_layout_header .header-sublink__pill {
    font-size: 0.75em;
  }
}
._comp_aem_layout_header .global_header_not_connected .main-header-nav .main-nav {
  font-size: var(--root-fs);
}
._comp_aem_layout_header .global_header_not_connected .main-header-nav .main-nav__popup {
  visibility: hidden;
  max-width: calc(90rem * 1.03);
  background-color: #fff;
  font-size: 0.75em;
  border-radius: 24px;
  box-shadow: 0 5px 70px 0 rgba(0, 0, 0, 0.35);
  margin-inline: auto;
  padding: 2em;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  display: flex;
  gap: 3.5em;
}
._comp_aem_layout_header .global_header_not_connected .main-header-nav .main-nav__popup__open {
  visibility: visible;
}
._comp_aem_layout_header .global_header_not_connected .main-header-nav .main-nav__popup__section-container {
  width: 100%;
  display: grid;
  gap: 2em 3.5em;
  grid-template-areas: "aa cc ff" "bb dd gg" "ex ex . ";
  grid-template-columns: repeat(3, 1fr);
  /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
  grid-template-rows: repeat(auto-fit, minmax(163px, max-content) minmax(max-content, 0) minmax(max-content, 0));
}
._comp_aem_layout_header .global_header_not_connected .main-header-nav .main-nav__popup__section:nth-child(1) {
  grid-area: aa;
}
._comp_aem_layout_header .global_header_not_connected .main-header-nav .main-nav__popup__section:nth-child(2) {
  grid-area: bb;
}
._comp_aem_layout_header .global_header_not_connected .main-header-nav .main-nav__popup__section:nth-child(3) {
  grid-area: cc;
}
._comp_aem_layout_header .global_header_not_connected .main-header-nav .main-nav__popup__section:nth-child(4) {
  grid-area: dd;
}
._comp_aem_layout_header .global_header_not_connected .main-header-nav .main-nav__popup__section:nth-child(5) {
  grid-area: ee;
}
._comp_aem_layout_header .global_header_not_connected .main-header-nav .main-nav__popup__section:nth-child(6) {
  grid-area: ff;
}
._comp_aem_layout_header .global_header_not_connected .main-header-nav .main-nav__popup__section:nth-child(7) {
  grid-area: gg;
}
._comp_aem_layout_header .global_header_not_connected .main-header-nav .main-nav__popup__section:nth-child(8) {
  grid-area: hh;
}
._comp_aem_layout_header .global_header_not_connected .main-header-nav .main-nav__popup__section > p {
  color: var(--dark-grey);
  font-size: calc(var(--root-fs) * 0.75);
  font-weight: 500;
  margin: 0 0 1em;
}
._comp_aem_layout_header .global_header_not_connected .main-header-nav .main-nav__popup__section__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
._comp_aem_layout_header .global_header_not_connected .main-header-nav .main-nav__popup__section__list li {
  margin-bottom: 1em;
}
._comp_aem_layout_header .global_header_not_connected .main-header-nav .main-nav__popup__section__list li:last-child {
  margin-bottom: 0;
}
._comp_aem_layout_header .global_header_not_connected .main-header-nav .main-nav__popup__section__more-links a {
  color: #787d80;
  text-decoration: none;
}
._comp_aem_layout_header .global_header_not_connected .main-header-nav .main-nav__popup__section__more-links a:hover {
  color: var(--color-brand-900);
}
._comp_aem_layout_header .global_header_not_connected .main-header-nav .main-nav__popup__section__more-links a.underline {
  text-decoration: underline;
}
._comp_aem_layout_header .global_header_not_connected .main-header-nav .main-nav__popup__extra {
  grid-area: ex;
  color: var(--color-blue-1100);
  text-decoration: underline;
  border-radius: 0.75em;
  border: solid 1px var(--pale-grey);
  align-items: center;
  padding: 0.75em;
  text-align: center;
}
._comp_aem_layout_header .global_header_not_connected .main-header-nav .main-nav__popup__extra:hover {
  border-color: var(--color-brand-900);
  background-color: var(--pale-blue);
}
@media (min-width: 1024px) {
  ._comp_aem_layout_header .global_header_not_connected .main-header-nav .main-nav__popup {
    margin-top: 0.5em;
  }
}
._comp_aem_layout_header .global_header_not_connected .main-header-nav .main-nav #popupHeaderNavCartes .main-nav__popup__section-container,
._comp_aem_layout_header .global_header_not_connected .main-header-nav .main-nav #popupHeaderNavCredits .main-nav__popup__section-container,
._comp_aem_layout_header .global_header_not_connected .main-header-nav .main-nav #popupHeaderNavAssurances .main-nav__popup__section-container {
  grid-template-areas: "aa bb dd" "ex ex . ";
}
._comp_aem_layout_header .global_header_not_connected .main-header-nav .main-nav #popupHeaderNavCredits .main-nav__popup__section-container {
  grid-template-areas: "aa cc dd" "bb .  . ";
}
._comp_aem_layout_header .global_header_not_connected .main-header-nav .main-nav #popupHeaderNavAssurances .main-nav__popup__section-container {
  grid-template-areas: "aa cc ." "bb dd  . ";
}
._comp_aem_layout_header .global_header_not_connected .main-header-nav .main-nav #popupHeaderNavSearchBar {
  padding: 0;
  display: block;
}
._comp_aem_layout_header .global_header_not_connected .main-header-nav .main-nav #popupHeaderNavSearchBar #navbar-search-menu {
  top: 0;
  margin: 0;
}
._comp_aem_layout_header .header-offer {
  --header-offer-p: 1.5rem;
  position: relative;
  grid-area: ad;
  height: -moz-fit-content;
  height: fit-content;
  max-width: 312px;
  background-color: var(--color-blue-800);
  border-radius: 16px;
  overflow: hidden;
  color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  padding: var(--header-offer-p);
}
._comp_aem_layout_header .header-offer > * {
  margin: 0;
}
._comp_aem_layout_header .header-offer--cornered-pill {
  padding-top: 2.5rem;
}
._comp_aem_layout_header .header-offer--splitted {
  padding: 0;
  background-color: transparent;
}
._comp_aem_layout_header .header-offer__title {
  font-size: 1.85rem;
  font-weight: bold;
  letter-spacing: inherit;
  line-height: inherit;
  unicode-bidi: initial;
  display: block;
  margin-bottom: 0.35rem;
}
._comp_aem_layout_header .header-offer__top {
  padding: var(--header-offer-p);
  background-color: var(--color-blue-200);
}
._comp_aem_layout_header .header-offer__top > * {
  margin: 0;
}
._comp_aem_layout_header .header-offer__top .header-offer__title {
  margin-bottom: 0;
  font-size: 1.375rem;
  color: var(--color-blue-800);
}
._comp_aem_layout_header .header-offer__info {
  font-size: 1rem;
  color: #fff;
}
._comp_aem_layout_header .header-offer--splitted.header-offer--pill-cornered .header-offer__top {
  padding-top: 2.5rem;
}
._comp_aem_layout_header .header-offer__bottom {
  padding: var(--header-offer-p);
  background-color: var(--color-brand-900);
}
._comp_aem_layout_header .header-offer__bottom--light-navy {
  background-color: var(--color-blue-800);
}
._comp_aem_layout_header .header-offer__bottom--black-navy {
  background-color: var(--color-blue-1100);
}
._comp_aem_layout_header .header-offer__bottom > * {
  margin: 0;
}
._comp_aem_layout_header .header-offer__bottom .header-offer__info {
  font-size: 0.875rem;
}
._comp_aem_layout_header .header-offer__pill {
  border: 1px solid;
  padding: 0.25rem 0.625rem;
  border-radius: 1rem;
  text-transform: uppercase;
  font-size: 0.6rem;
  font-weight: bold;
  display: inline-block;
  margin-bottom: 0.5rem;
  letter-spacing: inherit;
  line-height: inherit;
  unicode-bidi: initial;
}
._comp_aem_layout_header .header-offer__pill--red {
  color: var(--color-coral-700);
}
._comp_aem_layout_header .header-offer__pill--cornered {
  position: absolute;
  top: 0;
  left: 0;
  border: none;
  border-radius: 0 0 10px;
  padding: 0.5rem;
  color: #fff;
  background-color: #f0767e;
}
._comp_aem_layout_header .header-offer__condition {
  margin-top: 1.25rem;
}
._comp_aem_layout_header .header-offer__link {
  text-decoration: none;
  display: inline-block;
  margin-top: 1rem;
  padding: 0.875rem 2.5rem;
  text-transform: uppercase;
  font-size: 0.75rem;
  border-radius: 2rem;
  font-weight: bold;
  background-color: #eee;
  color: var(--color-blue-800);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}
._comp_aem_layout_header .header-offer__link--red {
  color: #fff;
  background-color: var(--color-coral-700);
  box-shadow: none;
}
._comp_aem_layout_header .header-offer__link--centered {
  margin-inline: auto;
  display: block;
  width: -moz-max-content;
  width: max-content;
}
@media (max-width: 767px) {
  ._comp_aem_layout_header .header-offer__link--centered-mobile {
    margin-inline: auto;
    display: block;
    width: -moz-max-content;
    width: max-content;
  }
}
@media (min-width: 768px) {
  ._comp_aem_layout_header .header-offer__link--centered-desktop {
    margin-inline: auto;
    display: block;
    width: -moz-max-content;
    width: max-content;
  }
}
._comp_aem_layout_header .header-offer__ml {
  display: block;
  text-align: right;
  margin-top: 0.75rem;
  font-size: 10px;
  letter-spacing: inherit;
  line-height: inherit;
  unicode-bidi: initial;
  font-weight: inherit;
}
@media (min-width: 1024px) {
  ._comp_aem_layout_header .header-offer--mobile {
    display: none;
  }
}
@media (max-width: 1023px) {
  ._comp_aem_layout_header .header-offer--mobile {
    max-width: unset;
  }
}
@media (max-width: 1023px) {
  ._comp_aem_layout_header .header-offer--desktop {
    display: none;
  }
}
._comp_aem_layout_header .global_header_not_connected .main-header-nav .grid-unset {
  grid-area: unset;
}
._comp_aem_layout_header .global_header_not_connected .main-header-nav .header-offer-offer-img {
  position: relative;
  overflow: hidden;
  z-index: 0;
  max-width: none;
}
@media (min-width: 1024px) {
  ._comp_aem_layout_header .global_header_not_connected .main-header-nav .header-offer-offer-img {
    width: 500px;
  }
}
._comp_aem_layout_header .global_header_not_connected .main-header-nav .header-offer-offer-img::before {
  content: "";
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: -61px;
  right: -118px;
  background-image: url(/content/dam/hellobank-neo-aquisition/components/header/new-hnc/header-offer.webp);
  background-size: 234px auto;
  background-position: right top;
  background-repeat: no-repeat;
}
@media (min-width: 1024px) {
  ._comp_aem_layout_header .global_header_not_connected .main-header-nav .header-offer-offer-img::before {
    background-size: 212px auto;
  }
}
._comp_aem_layout_header .connect-nav {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 1rem;
  text-align: center;
  /* stylelint-disable-next-line no-descending-specificity */
}
@media (max-width: 1023px) {
  ._comp_aem_layout_header .connect-nav {
    gap: 16px;
    flex-direction: column;
    align-items: stretch;
  }
  ._comp_aem_layout_header .connect-nav__more {
    margin-top: 8px;
  }
}
._comp_aem_layout_header .connect-nav__more {
  display: none;
}
._comp_aem_layout_header .connect-nav__more a {
  color: inherit;
  text-decoration: none;
  border-bottom: 1px solid var(--dark-grey);
}
@media (max-width: 1023px) {
  ._comp_aem_layout_header .connect-nav__more {
    display: initial;
  }
}
._comp_aem_layout_header .connect-nav__register a, ._comp_aem_layout_header .connect-nav__login a {
  height: 35px;
  display: flex;
  align-items: center;
  padding: 0 1.25rem;
  white-space: nowrap;
  text-transform: uppercase;
}
@media (max-width: 1023px) {
  ._comp_aem_layout_header .connect-nav__register a, ._comp_aem_layout_header .connect-nav__login a {
    justify-content: center;
  }
}
._comp_aem_layout_header .connect-nav__register a img, ._comp_aem_layout_header .connect-nav__login a img {
  margin-right: 0.5rem;
}
._comp_aem_layout_header .connect-nav__register a {
  background-color: var(--color-coral-700);
}
._comp_aem_layout_header .connect-nav__register a:hover {
  background-color: #7c131f;
}
._comp_aem_layout_header .connect-nav__login a {
  background-color: var(--color-brand-900);
}
._comp_aem_layout_header .connect-nav__login a:hover {
  background-color: #11a0b6;
}
._comp_aem_layout_header .global_header_not_connected .connect-nav a {
  box-shadow: none;
}
._comp_aem_layout_header .bottom-connect {
  z-index: var(--z-index-nav);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.625rem 1rem;
  position: fixed;
  bottom: 0;
  background-color: #fff;
  width: 100%;
  box-shadow: 0 -3px 12px 0 rgba(31, 39, 42, 0.08);
}
@media (min-width: 1024px) {
  ._comp_aem_layout_header .bottom-connect {
    display: none;
  }
}
._comp_aem_layout_header .bottom-connect .connect-nav {
  flex-direction: row;
}
._comp_aem_layout_header .global_header_not_connected.burger-menu-open + .bottom-connect {
  display: none;
}
@font-face {
  font-family: "hbicons";
  src: url("/etc.clientlibs/bnpparibas-neo-hb-acquisition-site-front/clientlibs/clientlib-base/resources/fonts/hbicons.woff2") format("woff2"), url("/etc.clientlibs/bnpparibas-neo-hb-acquisition-site-front/clientlibs/clientlib-base/resources/fonts/hbicons.woff") format("woff");
  font-display: swap;
  font-weight: normal;
  font-style: normal;
}
@font-face {
  src: url("/etc.clientlibs/bnpparibas-neo-hb-acquisition-site-front/clientlibs/clientlib-base/resources/fonts/.woff2") format("woff2"), url("/etc.clientlibs/bnpparibas-neo-hb-acquisition-site-front/clientlibs/clientlib-base/resources/fonts/.woff") format("woff");
  font-display: swap;
  font-weight: normal;
  font-style: normal;
}
._comp_aem_layout_header [class^=hbi-],
._comp_aem_layout_header [class*=" hbi-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "hbicons" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
._comp_aem_layout_header .hbi-burger-menu:before {
  content: "\e900";
}
._comp_aem_layout_header .hbi-search:before {
  content: "\e90e";
}
._comp_aem_layout_header .hbi-enter:before {
  content: "\e91b";
}
._comp_aem_layout_header .hbi-cross:before {
  content: "\e901";
}
._comp_aem_layout_header .hbi-chevron-down:before {
  content: "\e904";
}
._comp_aem_layout_header .hbi-chevron-left:before {
  content: "\e905";
}
._comp_aem_layout_header .hbi-chevron-right:before {
  content: "\e906";
}
._comp_aem_layout_header .hbi-phone:before {
  content: "\e90d";
}
._comp_aem_layout_header .hbi-user:before {
  content: "\e903";
}
._comp_aem_layout_header .hbi-info:before {
  content: "\e91d";
}
._comp_aem_layout_header .hbi-facebook:before {
  content: "\e909";
}
._comp_aem_layout_header .hbi-twitter:before {
  content: "\e90f";
}
._comp_aem_layout_header .hbi-instagram:before {
  content: "\e966";
}
._comp_aem_layout_header .hbi-youtube:before {
  content: "\e910";
}
._comp_aem_layout_header .hbi-download:before {
  content: "\e91c";
}
._comp_aem_layout_header .hbi-envelope:before {
  content: "\e921";
}
._comp_aem_layout_header .hbi-spark:before {
  content: "\e911";
}
._comp_aem_layout_header .hbi-zero-alt:before {
  content: "\e941";
}
._comp_aem_layout_header .hbi-avenir:before {
  content: "\e9db";
}
._comp_aem_layout_header .hbi-locker:before {
  content: "\e916";
}
._comp_aem_layout_header .hbi-faq {
  position: relative;
}
._comp_aem_layout_header .hbi-faq:before {
  position: relative;
  left: 0;
  content: "\e90a";
}
._comp_aem_layout_header .hbi-faq:after {
  position: absolute;
  content: "\e90b";
  opacity: 0.5;
  left: 0;
}
._comp_aem_layout_header .hbi-chat-conseiller {
  position: relative;
}
._comp_aem_layout_header .hbi-chat-conseiller:before {
  content: "\e903";
  position: relative;
}
._comp_aem_layout_header .hbi-chat-conseiller:after {
  content: "\e908";
  position: absolute;
  right: -5px;
  top: -6px;
}
._comp_aem_layout_header .global_header_not_connected:has(.main-header-nav--over) .pre-nav-container {
  transition: none;
}
._comp_aem_layout_header .header-sticky:has(.burger-menu-open) {
  top: 0;
}
._comp_aem_layout_header .global_header_not_connected .scroll-handler {
  height: 0;
}
._comp_aem_layout_header .global_header_not_connected.burger-menu-open .pre-nav-container {
  height: 0;
  overflow: hidden;
}
._comp_aem_layout_header .global_header_not_connected.burger-menu-open .scroll-handler {
  height: var(--header-h);
  animation: hold-height 0.3s;
  animation-timing-function: steps(1);
}
@keyframes hold-height {
  from {
    height: 0;
  }
  to {
    height: 0;
  }
}
._comp_aem_layout_header .global_header_not_connected .main-header-nav .burger-menu {
  all: unset;
  color: var(--color-brand-900);
  text-transform: uppercase;
  display: flex;
  align-items: center;
  gap: 6px;
}
._comp_aem_layout_header .global_header_not_connected .main-header-nav .burger-menu .hbi-burger-menu {
  font-size: 32px;
  line-height: 1;
  color: var(--color-brand-900);
}
._comp_aem_layout_header .global_header_not_connected .main-header-nav .burger-menu.open .hbi-burger-menu {
  color: #1f272a;
}
@media (min-width: 1024px) {
  ._comp_aem_layout_header .global_header_not_connected .main-header-nav .burger-menu {
    display: none;
  }
}
@media (max-width: 1023px) {
  ._comp_aem_layout_header .global_header_not_connected .main-header-nav .main-nav {
    transition: opacity 300ms;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    border: 0;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    word-wrap: normal;
  }
}
._comp_aem_layout_header .global_header_not_connected .main-header-nav .main-nav__btn-retour {
  display: none;
}
._comp_aem_layout_header .global_header_not_connected .main-header-nav .main-nav__btn-retour button {
  background: none;
  border: none;
  color: var(--color-brand-900);
  font-weight: 500;
  text-transform: uppercase;
  margin-left: -9px;
  padding: 1rem;
  padding-right: 2.5rem;
  padding-left: 0;
  display: flex;
  align-items: center;
}
._comp_aem_layout_header .global_header_not_connected .main-header-nav .main-nav__btn-retour i {
  font-size: 2rem;
}
._comp_aem_layout_header .global_header_not_connected.burger-menu-open .main-header-nav {
  flex-wrap: wrap;
  height: calc(100vh + 1px);
  height: calc(100dvh + 1px);
  align-items: flex-end;
  background-color: #fff;
  position: absolute;
  top: 0;
  animation: move-up 0.3s;
}
@keyframes move-up {
  from {
    top: auto;
  }
  to {
    top: 0;
  }
}
._comp_aem_layout_header .global_header_not_connected.burger-menu-open .main-header-nav .main-nav {
  visibility: visible;
  opacity: 1;
  position: initial;
  width: 100%;
  padding: 0;
  margin: 0;
  clip: unset;
  white-space: unset;
  word-wrap: unset;
  overflow: auto;
  padding-bottom: 4rem;
  height: calc(100% - var(--header-h));
  display: grid;
  align-content: space-between;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  align-items: stretch;
  margin-right: -10px;
  padding-right: 10px;
  z-index: 2;
}
._comp_aem_layout_header .global_header_not_connected.burger-menu-open .main-header-nav .main-nav .connect-nav {
  grid-row: none;
}
._comp_aem_layout_header .global_header_not_connected.burger-menu-open .main-header-nav .main-nav__main-links {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(6, max-content) 1fr;
  align-items: end;
}
._comp_aem_layout_header .global_header_not_connected.burger-menu-open .main-header-nav .main-nav__main-links .header-offer {
  margin-top: 22px;
}
._comp_aem_layout_header .global_header_not_connected.burger-menu-open .main-header-nav .main-nav__main-links__link {
  border-bottom: 1px solid #e0e0e0;
}
._comp_aem_layout_header .global_header_not_connected.burger-menu-open .main-header-nav .main-nav__main-links__link:nth-last-child(-n+2) {
  border-bottom: unset;
}
._comp_aem_layout_header .global_header_not_connected.burger-menu-open .main-header-nav .main-nav__main-links__link .btn-nav {
  width: 100%;
  padding: 22px 0;
  position: relative;
}
._comp_aem_layout_header .global_header_not_connected.burger-menu-open .main-header-nav .main-nav__main-links__link button.btn-nav::after {
  /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
  font-family: hbicons;
  content: "\e906";
  position: absolute;
  right: 0;
  font-size: 33px;
  color: var(--color-brand-900);
  top: 50%;
  transform: translateY(-50%);
}
._comp_aem_layout_header .global_header_not_connected.burger-menu-open .main-header-nav .main-nav__main-links__link .btn-nav-search {
  width: 100%;
  padding: 0.5rem 1rem;
  margin-block: 24px;
  justify-content: flex-start;
  border-color: #c1c8cc;
}
._comp_aem_layout_header .global_header_not_connected.burger-menu-open .main-header-nav .main-nav__main-links__link .btn-nav-search .hbi-search {
  display: inline-block;
  font-size: 2.5rem;
}
._comp_aem_layout_header .global_header_not_connected.burger-menu-open .main-header-nav .main-nav__main-links__link .btn-nav-search__text {
  display: initial;
  flex-grow: 1;
  padding-right: 1rem;
}
._comp_aem_layout_header .global_header_not_connected.burger-menu-open .main-header-nav .main-nav__popup {
  display: none;
  overflow: auto;
  font-size: inherit;
  border-radius: 0;
  padding-top: 1rem;
  box-shadow: none;
  z-index: 0;
}
._comp_aem_layout_header .global_header_not_connected.burger-menu-open .main-header-nav .main-nav__popup .main-nav__popup__section-container {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
._comp_aem_layout_header .global_header_not_connected.burger-menu-open .main-header-nav .main-nav__popup h3 {
  font-size: inherit;
}
._comp_aem_layout_header .global_header_not_connected.burger-menu-open.popup-open .main-header-nav .main-nav {
  grid-template-rows: max-content 1fr;
}
._comp_aem_layout_header .global_header_not_connected.burger-menu-open.popup-open .main-header-nav .main-nav__popup__open {
  display: flex;
  position: initial;
  padding-inline: 0;
  padding-right: 10px;
  width: 100%;
}
._comp_aem_layout_header .global_header_not_connected.burger-menu-open.popup-open .main-header-nav .main-nav__popup__open #navbar-search-menu {
  position: initial;
}
._comp_aem_layout_header .global_header_not_connected.burger-menu-open.popup-open .main-header-nav .main-nav__popup__open #navbar-search-menu > * {
  padding: 0;
}
._comp_aem_layout_header .global_header_not_connected.burger-menu-open.popup-open .main-header-nav .main-nav__popup__open #navbar-search-menu * {
  box-sizing: border-box;
}
._comp_aem_layout_header .global_header_not_connected.burger-menu-open.popup-open .main-header-nav .main-nav__popup__open #navbar-search-menu .container.faq.fp-faq > .row {
  display: contents;
}
._comp_aem_layout_header .global_header_not_connected.burger-menu-open.popup-open .main-header-nav .main-nav__popup__open #navbar-search-menu .container.faq.fp-faq > .row > .kol-sm-10.kol-sm-offset-1 {
  padding-inline: 0;
}
._comp_aem_layout_header .global_header_not_connected.burger-menu-open.popup-open .main-header-nav .main-nav__main-links {
  display: none;
}
._comp_aem_layout_header .global_header_not_connected.burger-menu-open.popup-open .main-header-nav .main-nav .connect-nav {
  display: none;
}
._comp_aem_layout_header .global_header_not_connected.burger-menu-open.popup-open .main-header-nav .main-nav__btn-retour {
  display: flex;
}
._comp_aem_layout_header.header-sticky {
  --scrollbar-width: 0px;
  --pre-nav-height: -88px;
  position: sticky;
  top: var(--pre-nav-height);
  z-index: var(--z-index-nav);
  margin-bottom: 0;
}
._comp_aem_layout_header .global_header_not_connected {
  --header-py: 20px;
  padding-top: 24px;
}
@media (max-width: 1023px) {
  ._comp_aem_layout_header .global_header_not_connected {
    padding-top: 0;
  }
}
@media (min-width: 1480px) {
  ._comp_aem_layout_header .global_header_not_connected {
    --header-py: 0px;
  }
}
@media (max-width: 1023px) {
  body.nav-mobile-open {
    overflow: hidden;
    height: 100vh;
  }
}
._comp_aem_layout_header .burger-menu.open .hbi-burger-menu::before {
  content: "\e901";
}
._comp_aem_layout_header .no-width {
  max-width: 0 !important;
  visibility: hidden;
}
._comp_aem_layout_header .no-height {
  max-height: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
._comp_aem_layout_header .navbar-search .hbi-cross {
  font-size: 200%;
  padding: 6px;
  border: none;
}
@media (min-width: 1024px) {
  ._comp_aem_layout_header .navbar-search {
    width: calc(100% - 30px);
    margin: 12px 15px;
    border-bottom: 1px solid #d8d8d8;
  }
}
._comp_aem_layout_header .search-form {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0 1.25rem;
  margin: 6px 0 9px;
  background-color: #fff;
  width: calc(100% - 3rem);
  flex-wrap: wrap;
}
@media (max-width: 479px) {
  ._comp_aem_layout_header .search-form {
    margin-left: 0;
    width: 100%;
  }
}
@media (min-width: 480px) {
  ._comp_aem_layout_header .search-form {
    font-size: 0.8125rem;
  }
}
._comp_aem_layout_header .search-form .hbi-search {
  color: var(--color-brand-900);
  font-size: 20px;
}
._comp_aem_layout_header .search-form input {
  border: 0;
  background-color: #fff;
  flex-grow: 1;
  min-width: 50%;
  line-height: 2;
  outline: none;
}
._comp_aem_layout_header .search-form--mobile input {
  padding-left: 0;
}
._comp_aem_layout_header .search-form--desktop .btn-search {
  visibility: hidden;
}
._comp_aem_layout_header .search-form label {
  max-width: 500px;
  overflow: hidden;
  transition: padding 0.4s ease-out, max-width 0.4s ease-out;
  white-space: nowrap;
  color: #1f272a;
  padding: 7px 20px;
  border-right: 1px solid var(--pale-grey);
  line-height: 2;
  font-size: 12px;
  font-weight: 500;
  padding-left: 5px;
}
._comp_aem_layout_header .search-form .btn-search {
  color: var(--color-brand-900);
  font-weight: normal;
  outline: none;
  border-radius: 0;
  border: none;
  background-color: #fff;
  padding: 6px;
  width: auto;
  margin-left: -30px;
}
._comp_aem_layout_header .search-form .btn-search .hbi-search,
._comp_aem_layout_header .search-form .btn-search .hbi-enter {
  display: inline-block;
  height: 2.5rem;
}
._comp_aem_layout_header .search-form .btn-search .hbi-search::before,
._comp_aem_layout_header .search-form .btn-search .hbi-enter::before {
  top: 5px;
  position: relative;
}
._comp_aem_layout_header .search-form .btn-search i {
  font-size: 2rem;
}
._comp_aem_layout_header .search-label {
  max-width: 200px;
  transition: max-width 0.2s linear;
}
._comp_aem_layout_header .fp-faq {
  padding-top: 2rem;
}
._comp_aem_layout_header .fp-faq a:not(.btn) {
  text-decoration: none;
  color: inherit;
}
@media (min-width: 1024px) {
  ._comp_aem_layout_header .fp-faq {
    margin-left: 128px;
    padding-bottom: 56px;
  }
}
._comp_aem_layout_header .fp-faq .btn-icon {
  --cta-bg: #fff;
  --cta-bg-hover: #fff;
  --cta-color: var(--color-neutral-1100);
  --cta-color-disabled: var(--cta-color);
  border: solid 1px var(--color-neutral-600);
  padding: 0.65rem 2.5rem 0.65rem 1.9rem;
  border-radius: 2rem;
  line-height: 2rem;
  font-weight: 300;
  text-transform: uppercase;
}
._comp_aem_layout_header .fp-faq .btn-icon i {
  font-size: 1.8rem;
  vertical-align: middle;
  margin-right: 0.65rem;
  color: var(--color-brand-900);
  text-align: left;
  width: 2.5rem;
  height: 2rem;
  display: inline-block;
}
._comp_aem_layout_header .fp-faq .btn-icon:hover {
  border-color: var(--color-brand-900);
}
._comp_aem_layout_header .fp-faq .block-btn-action {
  display: flex;
  margin-top: 2.2rem;
  padding-bottom: 0;
  gap: 1rem;
}
@media (max-width: 479px) {
  ._comp_aem_layout_header .fp-faq .block-btn-action {
    flex-direction: column;
  }
  ._comp_aem_layout_header .fp-faq .block-btn-action .btn-icon {
    width: 100%;
    font-size: 11px;
  }
}
._comp_aem_layout_header .title-list-faq {
  font-weight: 500;
  font-size: 1.375rem;
  color: #000;
  line-height: 1.5;
  margin: 1em 0;
}
@media (min-width: 768px) {
  ._comp_aem_layout_header .title-list-faq {
    padding-top: 20px;
  }
}
._comp_aem_layout_header .list-best-faq {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
}
._comp_aem_layout_header .list-best-faq li {
  padding-bottom: 0.5rem;
  line-height: 1.5;
  font-size: 16px;
}
._comp_aem_layout_header .list-best-faq a:hover {
  color: var(--color-brand-900);
}

#inbenta-modal-results ol {
  list-style: decimal;
}

.header-sticky:has(.burger-menu-open) {
  top: 0;
}